export const environment = {
    production: false,
    ENV: 'uat',
    API_URL: 'https://uat.campustrack.net/api',
    PAYMENT_URL: 'https://payments.campustrack.net/pgapi',
    ENABLE_WS: true,
    PHX_URL: 'wss://uat.campustrack.net/phx/socket',
    PHX_API_URL: 'https://uat.campustrack.net/phx/',
    ACCESS_CODE: '00ed8512-2b45-4853-a962-d2423ef61fb1',
    ACCESS_TOKEN: 'AccessToken',
    CT_CONTEXT: 'CT-Context',
    CT_CONTEXT_JSON: 'ct_context_json',
    DEFAULT_PHOTO_URL: '../../assets/img/blank-profile.png',
    TOAST_LOGIN_ERROR_DURATION: 2000,
    TOAST_POSITION_DEFAULT: 'top',
    EVENT_USER_LOGGED_IN: 'user:logged_in',
    EVENT_USER_LOGGED_OUT: 'user:logged_out',
    EVENT_USER_PERSONA_CHANGED: 'user:persona_changed',
    RECAPTCHA_SITE_KEY: '6LcyfnUUAAAAAC3fOBfvrg7IQDpmCvAqDhOVcLJn',
    SMS_INVENTORY_ID: '44c8ffd5-30d3-4104-9aef-eada1dcbfcce',
    EMAIL_INVENTORY_ID: 'a44c9400-9a76-43d0-b365-0cf9c5408a41',
    BITLOK_ORG_ID: '448b2463-b5c5-403e-ad9e-ff8a5ac7ed95',
    ICON_IMAGE_URL:'../../assets/img/ct_icon.png',
    PAYU_KEY:'9XGZQpPh',
    CT_DASHBOARD_ACCESS_CODE: 'd1d4ac73-952e-441c-86f5-06159c70a936',
    PUBLIC_TOPIC_ID: 'e7a7f388-d461-4a6b-a681-64b4c992d6fb',
    RELEASE_VERSION: '1.0.0',
    RELEASE_DATE: '07-Jun-2024, 10:00 AM'
  };
  
